import { useNavigate,Link } from "react-router-dom";
import { Fade } from "react-awesome-reveal";
import { motion, useScroll } from "framer-motion";

import '../../../App.scss';
import ServDesWork from '../ServDes';
import Projects from '../Projects';
import PastWork from '../PastWork';
import Personal from '../Personal';
import HeaderBar from '../headerBar';
import Reveal from '../../functions/Reveal';
import Hover from '../../functions/Hover';
import resume from '../../../Michael.Cameron_Resume.pdf'
import DCMA from './DCMA';
import Chatbot from '../../functions/Chatbot';

import Manufacturing from '../../../images/Compressed/manufacturing.jpg';
import Farming from '../../../images/farming.jpg';
import FNS from '../../../images/FNS.jpg';
import Haiti from '../../../images/Haiti_Girl.jpg';

function Home() {
  let Path = null;
  const navigate = useNavigate();

  const routeChange = () => {
      navigate(Path);
  }

  return   <>
  
  <div className='section-block text-section'> 
    <div className="text-card header-card">
      <h3> Michael Cameron </h3>
    </div>
    <div className="text-card header-card sub-header-card">
      <h3> Currently Lead UX Designer at <a href="https://www.state.gov/bureaus-offices/under-secretary-for-management/bureau-of-consular-affairs/" target="blank">DOS Bureau of Consular Affairs </a></h3>
    </div>
    <div className="text-card header-card sub-header-card">
      <h3> Northern Virginia/ Washington DC Area </h3>
    </div>
  </div>

  <div className="container title-card"> 
    <div className="section-block text-section title-section-text">
        <div className="text-block row title-block ">
            <h1> DESIGNER </h1>
        </div>
        <div className="text-card m-right-side">
            <p>
              I specialize in collaborating with cross-functional teams to create strategic solutions on a range of digital platforms - currently within the federal space </p>
        </div>
        </div>
   
    </div>
    <Chatbot/>
    <Reveal>
      <div className="container"> 
      <div className="section-block text-section title-section-text ">
       
          <div className="text-block title-block intro-block" >
             <h2> Every project brings a unique ecosystem of people, roles, processes, and culture. My goal is to understand current orchestrations and map out 
                  pathways to a desired state.  
              </h2>
              
          </div>
              
          <div className="text-card m-right-side " style={{height:'100%'}}  >
            <Link to={`/WORKING`} className="hover-card" >                
              <h2> How I Work</h2>
              </Link>
           </div>
          
              
      </div>     
    </div>
    </Reveal> 
           
    <HeaderBar Descr="CURRENT WORK" Location="SERVICE DESIGNER @ STEAMPUNK" time ="2019 - PRESENT"/>
 
    <Projects Path= {"./USAID"} text1="USAID: PARTNER RELATIONSHIP MANAGEMENT SYSTEM" prjctImage= {Haiti}  text2="USAID forms powerful partnerships with businesses and investors to advance global development, leveraging unique resources and expertise for shared success."/>
    <Projects Path= {"./DCMA"}  text1="DEFENSE CONTRACT MANAGEMENT AGENCY" prjctImage= {Manufacturing}  text2="The Defense Contract Management Agency provides contract administration services for the Department of Defense, other federal organizations and international partners, and is an essential part of the acquisition process from pre-award to sustainment."/>
    <Projects Path= {"./NASS"} text1="USDA: NATIONAL AGRICULTURE STATISTICS SERVICE " prjctImage= {Farming}  text2="The USDA's National Agricultural Statistics Service (NASS) conducts hundreds of surveys every year and prepares reports covering virtually every aspect of U.S. agriculture."/>
    <Projects Path= {"./FNS"} text1="USDA: FOOD & NUTRITION SERVICE" prjctImage= {FNS}  text2="USDA FNS' mission is to increase food security and reduce hunger by providing children and low-income people access to food, a healthful diet and nutrition education in a way that supports American agriculture and inspires public confidence."/>
    

    <HeaderBar Descr="SUMMARIES OF PAST WORK" Location="GRAPHIC ANALYST @HSSI" time ="2013 - 2019"/>
    <PastWork/> 
    <HeaderBar Descr="PERSONAL WORK" Location="JUST FOR MYSELF" time ="2013 - PRESENT"/>
    <Personal/>
    <HeaderBar Descr="PERSONAL INFO" Location="TO GET IN TOUCH" time =" - "/>
  



     <div className='container project'> 

        <div className="section-block text-section contact-footer"> 
          <div className="text-card" style={{width:'100%'}}>
              <h1 >MICHAEL CAMERON <br/>   
              <a id="openModal">  <h1 className="hover-underline-animation">  mike@mcameron.io  </h1></a> 
              </h1>
          </div> 
              
           
       
          <div className="hover-card" >
          <div className="text-card" > 
                  <a href="https://www.linkedin.com/in/michael-cameron-06b721a9?lipi=urn%3Ali%3Apage%3Ad_flagship3_profile_view_base_contact_details%3B%2FeKe95voQhOmrSTpj1Ql%2Bg%3D%3D" target="_blank" ><h3 > LinkedIn</h3></a>
                </div>
            </div>
            <div className="hover-card" >
              <div className="text-card">   
                  <a href={resume} target="_blank" ><h3 > Download Resume</h3></a>
              </div>
           </div>
           </div> 
    </div>
    

    </>
}
export default Home;
